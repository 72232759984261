<template>

<div class="inner">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.inner {
	max-width: 1120px;
	margin: 0px auto;
	width: 100%;
	padding: 0px 20px;
}

.is-tablet .inner {
	padding: 0px;
}

.is-mobile .inner {
	padding: 0px 10px;
}

</style>
